import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup'; 
import { Dialog } from "primereact/dialog";
import moment from "moment";
import { AutoComplete } from "primereact/autocomplete";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { useQuery, gql, useLazyQuery } from "@apollo/client";
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";

const THEMES = gql`
  
  query getThemes($email: String!) {
    days(sort: "Date:asc") {
      data {
        id
        attributes {
          Title
          Date
        }
      }
    }
    usersPermissionsUsers(filters: {email: { eq: $email }}){
      data{
        id,
        attributes{
          ShowBanner
        }
      }
    }
    themes(sort: "id:asc") {
      data {
        id
        attributes {
          Title
          IconName
          ColorCode
          Description
        }
      }
    }
    containers(
      sort: ["day.id:asc", "SortNo:asc"]
      pagination: { start: 0, limit: 300 }
      filters: { ContentCategory: { eq: "Keynote_Video" } }
    ) {
      data {
        id
        attributes {
          Title
          SessionID
          Author
          Image {
            data {
              attributes {
                name
                url
                ext
              }
            }
          }
          theme {
            data {
              attributes {
                IconName
                ColorCode
                Description
              }
            }
          }
          day {
            data {
              id
              attributes {
                Title
                Date
              }
            }
          }
          session {
            data {
              id
              attributes {
                Title
              }
            }
          }
        }
      }
    }
  }
`;

const THEME = gql`
  query getSessions($id: ID!) {
    theme(id: $id) {
      data {
        id
        attributes {
          Title
          IconName
          Description
          containers(
            pagination: { start: 0, limit: 300 }
            sort: ["day.id:asc", "session.id:asc"]
            filters: {
              or: [
                { ContentCategory: { eq: "Daily_Summary_Video" } }
                { ContentCategory: { eq: "Keynote_Video" } }
                { ContentCategory: { eq: "Opening_Ceremony" } }
                { ContentCategory: { eq: "Title" } }
              ]
            }
          ) {
            data {
              id
              attributes {
                Title
                SessionID
                theme {
                  data {
                    attributes {
                      IconName
                      ColorCode
                    }
                  }
                }
                day {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
                session {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SESSION = gql`
  query getSessions($id: ID!) {
    day(id: $id) {
      data {
        id
        attributes {
          Title
          Date
          containers(
            sort: ["session.id:asc", "SortNo:asc"]
            pagination: { start: 0, limit: 300 }
            filters: {
              or: [
                { ContentCategory: { eq: "Daily_Summary_Video" } }
                { ContentCategory: { eq: "Keynote_Video" } }
                { ContentCategory: { eq: "Opening_Ceremony" } }
                { ContentCategory: { eq: "Title" } }
              ]
            }
          ) {
            data {
              id
              attributes {
                Title
                SessionID
                theme {
                  data {
                    attributes {
                      IconName
                      ColorCode
                    }
                  }
                }
                day {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
                session {
                  data {
                    id
                    attributes {
                      Title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const SEARCH = gql`
  query getSearch($key: String!) {
    containers(
      filters: {
        or: [
          { Title: { containsi: $key } }
          { Author: { containsi: $key } }
          { Tag: { containsi: $key } }
          { Description: { containsi: $key } }
        ]
      }
    ) {
      data {
        id
        attributes {
          Title
          Author
          SessionID
          day {
            data {
              id
              attributes {
                Title
              }
            }
          }
          session {
            data {
              id
              attributes {
                Title
              }
            }
          }
          theme {
            data {
              attributes {
                IconName
                ColorCode
              }
            }
          }
        }
      }
    }
  }
`;

export default function Home() {
  const { loading, error, data } = useQuery(THEMES, {
    variables: { email: JSON.parse(localStorage.getItem("token")).user.username },
  });
  const [blockedPanel, setBlockedPanel] = useState(false);
  const [sessions, setSessions] = useState({});
  const [displayBasic, setDisplayBasic] = useState(false);
  const [sessionsday, setSessionsday] = useState({});
  const [displayBasicTwo, setDisplayBasicTwo] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [items, setitems] = useState([]);

  const [executeQuery] = useLazyQuery(THEME, {
    onCompleted: (someData) => {
      if (someData) {
        setSessions(someData);
        setDisplayBasic(true);
        setBlockedPanel(false);
      }
    },
    onError: (err) => {
      setBlockedPanel(false);
    },
  });

  const [executeQuery2] = useLazyQuery(SESSION, {
    onCompleted: (someData) => {
      if (someData) {
        setSessionsday(someData);
        setDisplayBasicTwo(true);
        setBlockedPanel(false);
      }
    },
    onError: (err) => {
      setBlockedPanel(false);
    },
  });

  const [executeQuery3] = useLazyQuery(SEARCH, {
    onCompleted: (someData) => {
      var dataL = [{ label: "Programme", code: "PO", items: [] }];
      someData.containers.data.map((poster) => {
        var pos = {
          label: poster.attributes.Title,
          value: poster.id,
          SessionID: poster.attributes.SessionID,
          session: poster.attributes.session.data.id,
          day: poster.attributes.day.data.id,

          Author: poster.attributes.Author,
          theme: poster.attributes.theme.data,
          sessions: poster.attributes.session.data,
          days: poster.attributes.day.data,
        };
        dataL[0].items.push(pos);
      });
      setitems(dataL);
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const searchCountry = (event) => {
    executeQuery3({
      variables: { key: event.query },
    });
  };

  if (loading) {
    return (
      <BlockUI blocked="true" fullScreen>
        <ProgressSpinner />
      </BlockUI>
    );
  }
  if (error) {
    setBlockedPanel(false);
    return;
  }

  const onClick = (position) => {
    setBlockedPanel(true);

    executeQuery({
      variables: { id: position },
    });
  };

  const onHide = (name) => {
    setSessions({});
    setDisplayBasic(false);

    setSessionsday({});
    setDisplayBasicTwo(false);
  };

  const onClick2 = (position) => {
    setBlockedPanel(true);
    executeQuery2({
      variables: { id: position },
    });
  };

  const setSelected = (item) => {
    console.log(item);
    window.location.href =
      "/session/" + item.day + "/" + item.SessionID + "/" + item.session;
  };

  const itemTemplate = (item) => {
    if (item.label === undefined) {
      return;
    }
    console.log(item);
    return (
      <div
        key="theme.id"
        className="d-flex align-items-center p-3 m-0"
        style={{ background: "#" + item?.theme?.attributes?.ColorCode }}
      >
        <img
          className="img-track me-3"
          src={require("../assets/img/" +
            item?.theme?.attributes?.IconName +
            ".png")}
          alt=""
        />
        <div>
          <p className="fs-6 m-0 fw-bold white-space-break">
            {item?.SessionID}&nbsp;{item?.label}
          </p>
          <p className="fs-7 m-0 fw-normal d-flex align-items-center">
            <i className=" fs-7 pi pi-user"></i> &nbsp; {item?.Author}{" "}
            &nbsp;&nbsp;&nbsp;
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      {blockedPanel ? (
        <BlockUI blocked={blockedPanel} fullScreen>
          <ProgressSpinner />
        </BlockUI>
      ) : (
        <></>
      )}

      

      <div className="banner container-fluid">
        <div className="row">
          <div className="col-md-12 p-5">
            <h4>Shaping our Water Future</h4>
            <h1 className="fw-bolder" style={{ fontSize: "60px" }}>
              IWA World Water Congress & Exhibition
            </h1>
            {/* <h4>
              Browse the keynotes and technical presentations
            </h4> */}
            <div className="d-flex align-items-center">
              <button onClick={() => window.open(`https://worldwatercongress.org/wp-content/uploads/2024/08/IWA_WWCE_2024_Programme_Guide_Web_2.pdf`, '_blank')} type="button" class="btn btn-light me-3" >
                Download Programme Book 
              </button>
              <button onClick={() => window.open(`https://www.flickr.com/photos/iwawater/albums/72177720319528172`, '_blank')} type="button" class="btn btn-light" >
                WWCE 2024 Photos
              </button>
            </div>
          </div>
        </div>
      </div>


      {
        (data.usersPermissionsUsers.data[0].attributes.ShowBanner === 'Non Members' || data.usersPermissionsUsers.data[0].attributes.ShowBanner === 'Trial Member') ? (
         

          <div class="container my-3">
            <div class="coupon-card">
                <div class="row">
                  <div class="col-md-8 d-flex align-items-center justify-content-center">
                    <div>
                      <h1>Time to Renew.</h1>
                      <p class="px-5">Your membership is currently inactive or expiring. Limited-time offer!<br/><strong><b>25% discount</b>. Valid until July 30th, 2023.</strong> </p>

                    </div>
                  </div>
                  <div class="col-md-4 d-flex align-items-center justify-content-center">
                      <div>
                      <di class="coupon-row">
                        <span id="cpnCode">IWA25WWCE22WEB</span>
                        <span id="cpnBtn" onClick={() => {navigator.clipboard.writeText('IWA25WWCE22WEB'); alert('Copied..!')}}>Copy Code</span>
                      </di>

                      <p><a href="">Click here</a> to Join/Renew</p>
                      </div>
                  </div>
                </div>
                <div class="circle1"></div>
                <div class="circle2"></div>
            </div>
          </div>

        ):(
          <></>
        )
      }

      {
        (data.usersPermissionsUsers.data[0].attributes.ShowBanner === 'Lapsed Members' || data.usersPermissionsUsers.data[0].attributes.ShowBanner === 'Renewal Window') ? (
          <div class="container my-3">
            <div class="coupon-card">
                <div class="row">
                  <div class="col-md-8 d-flex align-items-center justify-content-center">
                    <div>
                      <h1>Time to Renew.</h1>
                      <p class="px-5">Your membership is currently inactive or expiring. Limited-time offer!<br/><strong><b>20% discount</b>. Valid until July 30th, 2023.</strong> </p>

                    </div>
                  </div>
                  <div class="col-md-4 d-flex align-items-center justify-content-center">
                      <div>
                      <di class="coupon-row">
                        <span id="cpnCode">IWA20WWCE22WEB</span>
                        <span onClick={() => {navigator.clipboard.writeText('IWA20WWCE22WEB'); alert('Copied..!')}} id="cpnBtn">Copy Code</span>
                      </di>

                      <p><a href="">Click here</a> to Join/Renew</p>
                      </div>
                  </div>
                </div>
                <div class="circle1"></div>
                <div class="circle2"></div>
            </div>
          </div>
        ):(
          <></>
        )
      }



      <div className="container-fluid mt-0" style={{ background: "#00a4e7" }}>
        <div className="container pt-4">
          <div className="row">
            <div class="col-md-6 p-3 py-4">
              <iframe
                src="https://www.youtube.com/embed/4wT04WvmCRg?si=6efbZVopFkjydOEm"
                width="100%"
                height="300"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                title="13-09 - DAY 3 - Summary.mp4"
              ></iframe>
            </div>
            <div class="col-md-6 p-4">
              <p
                style={{ color: "#fff" }}
                className="card-text text-align-justify fs-6"
              >
                This website offers comprehensive access to the 2024 IWA World Water Congress & Exhibition's exclusive content. Explore the full programme, keynote sessions, 400+ poster presentations, and 200+ technical sessions and workshops held in Toronto, Canada. The content is organised around the original Congress programme and categorised into six main tracks for easy navigation. Use the search bar below to find specific topics and sessions.
              </p>
              {/*<div className="d-flex align-items-center">
                <img
                  className="card-img-top pres-image me-4"
                  src="https://worldwatercongress.org/wp-content/uploads/2021/11/49176692263_b67eb3d21c_c.jpg.webp"
                />
                <p style={{ color: "#fff" }} className="fs-6 fw-bold mb-0">
                  TOM MOLLENKOPF <br />
                  <small>IWA President</small>
                </p>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid mt-0 g-event"
        style={{ background: "#000" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div class="col-md-12 p-4 py-4">
              <div className="search-bar searchbar-in ms-auto me-3">
                <i className="bi bi-search-heart ms-2 me-2"></i>
                <AutoComplete
                  placeholder="Seach by Title/Author/Tag..."
                  value={selectedItem}
                  suggestions={items}
                  completeMethod={searchCountry}
                  onChange={(e) => setSelectedItem(e.value)}
                  onSelect={(e) => setSelected(e.value)}
                  field="label"
                  optionGroupLabel="label"
                  optionGroupChildren="items"
                  itemTemplate={itemTemplate}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3" id="KeyNotes">
        <div className="row">
          <div class="col-md-12">
            <div className="p-4 w-100">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="fw-bolder mb-1">Keynotes</h5>
                  <p>
                    <small className="fs-7">
                      Shaping the future of water management
                    </small>
                  </p>
                </div>
                <span className="fs-7" style={{ width: "55px" }}>
                  {/*<Link to="/session">
                    <i className="pi pi-arrow-right fs-7"></i> &nbsp; See all
                  </Link>*/}
                </span>
              </div>
              <Swiper
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 5,
                  },
                }}
                navigation
                pagination={{ clickable: true }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {data.containers.data.map((date) => (
                  <SwiperSlide key={date.id}>
                    <div className="p-0  cursor">
                    <Link to={`/session/${date?.attributes?.day?.data?.id}/${date?.attributes?.SessionID}/${date?.attributes?.session?.data?.id}`}>
                      <div className="card p-3">
                        <p className="fw-600 mb-1 ">
                          {date?.attributes?.day?.data?.attributes?.Title}
                        </p>
                        <AvatarGroup> 
                          {date?.attributes?.Image?.data.length === 0 ? (
                            date?.attributes?.Author.split(",").map((txt) => (
                              <Avatar label={txt.charAt(0)} className="mt-3 mb-2" size="xlarge" shape="circle" />
                            ))
                          ): (
                            date?.attributes?.Image?.data.map((img) => (
                              <Avatar image={img?.attributes?.url} className="mt-3 mb-2" size="xlarge" shape="circle" />
                            ))
                          ) }
                        </AvatarGroup>
                        <p className="fw-500 mb-3 text " >
                          <small>{date.attributes.Title}</small>
                        </p>
                      </div>
                    </Link>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3" id="ThematicTracks">
        <div className="row">
          <div class="col-md-12">
            <div className="p-4 w-100">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="fw-bolder mb-1">Thematic Tracks</h5>
                  <p>
                    <small className="fs-7">
                      Shaping the future of water management
                    </small>
                  </p>
                </div>
                <span className="fs-7" style={{ width: "55px" }}>
                  {/*<Link to="/session">
                    <i className="pi pi-arrow-right fs-7"></i> &nbsp; See all
                  </Link>*/}
                </span>
              </div>
              <Swiper
                className="w-100"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 6,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {data.themes.data.map((date) => (
                  <SwiperSlide key={date.id}>
                    <div className="p-0  cursor">
                      <div
                        className="card h-100 d-flex justify-content-between min-h-100 p-3"
                        onClick={() => onClick(date.id)}
                        style={{ background: "#" + date.attributes?.ColorCode }}
                        tooltip="Enter your username"
                      >
                        <img
                          className="img-track"
                          src=""
                          alt=""
                        />
                        <div>
                          <p className="fw-600 mb-3" >{date.attributes.Title}</p>
                          <button
                            onClick={() => onClick(date.id)}
                            type="button"
                            class="btn btn-primary"
                          >
                            Explore 
                          </button>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-3" id="DailyView">
        <div className="row">
          <div class="col-md-12">
            <div className="p-4 w-100">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="fw-bolder mb-1">Daily Views</h5>
                  <p>
                    <small className="fs-7">
                      Shaping the future of water management
                    </small>
                  </p>
                </div>
                <span className="fs-7" style={{ width: "55px" }}>
                  {/*<Link to="/session">
                    <i className="pi pi-arrow-right fs-7"></i> &nbsp; See all
                  </Link>*/}
                </span>
              </div>
              <Swiper
                className="w-100"
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={5}
                slidesPerView={1}
                breakpoints={{
                  640: {
                    slidesPerView: 5,
                  },
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {data.days.data.map((date) => (
                  <SwiperSlide key={date.id}>
                    <div className="p-0  cursor">
                      <div className="card p-3" onClick={() => onClick2(date.id)}>
                        <p className="fw-600 mb-1">
                          {moment(date.attributes.Date).format("dddd DD, MMM")}
                        </p>
                        <p className="fw-500 mb-0 " >
                          <small>{date.attributes.Title}</small>
                        </p>
                        <i class="bi bi-calendar-week mt-2 mb-2"></i>
                        
                        <button
                          onClick={() => onClick2(date.id)}
                          type="button"
                          class="btn btn-primary"
                        >
                          Explore
                        </button>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-0 g-event">
        <div className="container pt-4">
          <div className="row">
            <div class="col-md-3 p-4 py-4">
              <p
                style={{ color: "#fff" }}
                className="card-text text-align-start fw-bolder fs-3"
              >
                The global event for water professionals covering the entire
                water cycle
              </p>
            </div>
            <div class="col-md-9 p-4">
              <p
                style={{ color: "#fff" }}
                className="card-text text-align-start fw-bolder fs-5"
              >
                The 2024 IWA World Water Congress & Exhibition was held in Toronto, Canada, from 11-15 August 2024, attracting 7,000 participants from 106 countries. The event featured 250 exhibitors and 900+ presenters, making it one of the largest and impactful editions. It brought together water professionals, urban planners, industry leaders, and experts from diverse fields to address global water challenges. A key focus was on Canada’s water issues, including climate resilience and Indigenous water rights. Aligned with SDG 6 goals , the Congress showcased innovative solutions and fostered collaboration, setting new standards for global water sustainability and innovation.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="container-fluid mt-0 g-event"
        style={{ background: "#000" }}
      >
        <div className="container pt-4">
          <div className="row align-items-center">
            <div class="col-md-4 p-4 py-4">
              <img
                className="w-100 p-4"
                src="https://strapiimagetwo.s3.eu-west-2.amazonaws.com/pngwing_com_114a4f7ece.png"
              />
            </div>
            <div class="col-md-8 p-4">
              <p
                style={{ color: "#fff" }}
                className="card-text text-align-start fw-bolder fs-4"
              >
                This Congress was the first in which IWA held a High-Level Summit, connecting leading figures from the water sector with national and city politicians around the theme of water as a key to action on climate and the Sustainable Development Goals.
              </p>
            </div>
          </div>
        </div>
      </div> */}

      {/*<div
        className="container-fluid mt-0 g-event"
        style={{ background: "#00a4e7" }}
      >
        <div className="container pt-4">
          <div className="row align-items-center">
            <div class="col-md-8 p-4 py-4">
              <h5 style={{ color: "#fff" }} className="fw-bolder fs-2">
                Introduction from the Danish Government
              </h5>
              <br />
              <p style={{ color: "#fff" }} className="fw-bold fs-5">
                Danish Environment Minister Lea Wermelin looks forward to
                welcoming you at the #WorldWaterCongress & Exhibition in
                Copenhagen!
              </p>

              <p style={{ color: "#fff" }} className="fw-bold fs-5">
                Check out her video on the right, which is also available with
                English or Mandarin subtitles.
              </p>
            </div>
            <div class="col-md-4 p-4">
              <iframe
                width="360"
                height="203"
                src="https://www.youtube.com/embed/QrnLZJqd3mI"
                title="Danish Environment Minister Lea Wermelin looks forward to welcoming you in Copenhagen 2022"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>*/}

      <div
        className="container-fluid mt-0 g-event"
        style={{ background: "#e2f4fe" }}
      >
        <div className="container pt-4">
          <div className="row align-items-center">
            <div class="col-md-12 p-4 py-4">
              <img
                className="w-100 p-4"
                src="https://worldwatercongress.org/wp-content/uploads/2023/01/Join-the-international-water-association-1024x313.jpg"
              />
            </div>
          </div>
        </div>
      </div>

      <Dialog
        header={
          "Sessions belonging to Track " +
          sessions?.theme?.data?.id +
          " - " +
          sessions?.theme?.data?.attributes.Title
        }
        visible={displayBasic}
        style={{ width: "90vw" }}
        onHide={() => onHide("displayBasic")}
      >
        <p>{sessions?.theme?.data?.attributes.Description}</p>
        <div className="table-responsive">
          <table className="table  table-hover mb-1  table center table-centered table-nowrap table">
            <tbody>
              {sessions?.theme?.data?.attributes.containers.data.map(
                (theme) => (
                  <tr
                    key="theme.id"
                    style={{
                      background:
                        "#" +
                        theme?.attributes?.theme?.data?.attributes?.ColorCode,
                    }}
                  >
                    <td className="align-middle">
                      <img
                        className="img-track"
                        src={require("../assets/img/" +
                          theme?.attributes?.theme?.data?.attributes?.IconName +
                          ".png")}
                        alt=""
                      />
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.day?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.session?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle w-100">
                      <p className="fs-6 m-0 fw-light capital">
                        {theme?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle min-max-125">
                      <Link
                        to={`/session/${theme?.attributes?.day?.data?.id}/${theme?.attributes?.SessionID}/${theme?.attributes?.session?.data?.id}`}
                      >
                        <button type="button" class="btn btn-primary">
                          View details
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Dialog>

      <Dialog
        header="Sessions of the day"
        visible={displayBasicTwo}
        style={{ width: "90vw" }}
        onHide={() => onHide("displayBasic")}
      >
        <div className="table-responsive">
          <table className="table  table-hover mb-1  table center table-centered table-nowrap table">
            <tbody>
              {console.log(sessionsday)}
              {sessionsday?.day?.data?.attributes.containers.data.map(
                (theme) => (
                  <tr
                    key="theme.id"
                    style={{
                      background:
                        "#" +
                        theme?.attributes?.theme?.data?.attributes?.ColorCode,
                    }}
                  >
                    <td className="align-middle">
                      <img
                        className="img-track"
                        src={require("../assets/img/" +
                          theme?.attributes?.theme?.data?.attributes?.IconName +
                          ".png")}
                        alt=""
                      />
                    </td>
                    <td className="align-middle text-center min-max-100">
                      <p className="fs-6 m-0 fw-regular">
                        {theme?.attributes?.session?.data?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle w-100">
                      <p className="fs-6 m-0 fw-light capital">
                        {theme?.attributes?.Title}
                      </p>
                    </td>
                    <td className="align-middle min-max-125">
                      <Link
                        to={`/session/${theme?.attributes?.day?.data?.id}/${theme?.attributes?.SessionID}/${theme?.attributes?.session?.data?.id}`}
                      >
                        <button type="button" class="btn btn-primary">
                          View details
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </Dialog>
    </>
  );
}
