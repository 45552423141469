import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { Player } from "video-react";
import "../../node_modules/video-react/dist/video-react.css"; // import css node_modules/video-react/dist/video-react.css
import { BlockUI } from "primereact/blockui";
import { ProgressSpinner } from "primereact/progressspinner";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import Iframe from "react-iframe";
import { Button } from "primereact/button";
import { Avatar } from 'primereact/avatar';
import { AvatarGroup } from 'primereact/avatargroup';
import ReactMarkdown from "react-markdown";

const SESSION = gql`
  query getSession($day: ID!, $sessionId: String!, $sessionNo: ID!) {
    containers(
      pagination: { start: 0, limit: 300 }
      filters: {
        SessionID: { eq: $sessionId }
        and: {
          session: { id: { eq: $sessionNo } }
          and: { day: { id: { eq: $day } } }
        }
      }
    ) {
      data {
        id
        attributes {
          Title
          Description
          SessionID
          ContentCategory
          Author
          Image {
            data {
              attributes {
                name
                url
                ext
              }
            }
          }
          Tag
          Document {
            File {
              data {
                attributes {
                  name
                  url
                  ext
                }
              }
            }
          }
          Presentation {
            File {
              data {
                attributes {
                  name
                  url
                  ext
                }
              }
            }
          }
          Video {
            File {
              data {
                attributes {
                  url
                }
              }
            }
          }
          Poster {
            File {
              data {
                attributes {
                  url
                  ext
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function SessionDetail() {
  const { day, sessionId, sessionNo } = useParams();
  const [displayBasicTwo, setDisplayBasicTwo] = useState(false);
  const [docs, setDocs] = useState([]);
  const [ext, setExt] = useState();

  const { loading, error, data } = useQuery(SESSION, {
    variables: {
      day: day,
      sessionId: sessionId,
      sessionNo: sessionNo,
    },
  });

  const MyLoadingRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";

    if (fileText) {
      return (
        <div className="w-100 text-center">
          <h4>Loading File ({fileText})...</h4>
        </div>
      );
    }

    return (
      <div className="w-100 text-center">
        <h4>Loading File...</h4>
      </div>
    );
  };

  const MyNoRenderer = ({ document, fileName }) => {
    const fileText = fileName || document?.fileType || "";

    if (fileText) {
      return <div>No Renderer Error! ({fileText})</div>;
    }

    return <div>No Renderer Error!</div>;
  };

  if (loading) {
    return (
      <BlockUI blocked="true" fullScreen>
        <ProgressSpinner />
      </BlockUI>
    );
  }
  if (error) return `Error! ${error.message}`;

  //console.log(data);

  const onClick2 = (position) => {
    setExt(position.ext);
    setDocs([{ uri: position.url }]);
    setDisplayBasicTwo(true);
  };

  const onHide = (name) => {
    setDisplayBasicTwo(false);
  };

  return (
    <>
      <main id="main" className="main mb-5">
        <div className="pagetitle">
          <h1>Detailed Programme</h1>
          <nav>
            <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link className="d-flex align-items-center fs-7" to="/"><i class="pi pi-arrow-left"></i>&nbsp; &nbsp;Back </Link>
              </li>
              {/* <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li> */}
              {/* <li className="breadcrumb-item">
                <Link to="/session">Programme</Link>
              </li> */}
              <li className="breadcrumb-item active">Detailed Programme</li>
            </ol>
          </nav>
        </div>
        {/* End Page Title */}
        <section className="section card p-4 dashboard">
          <div className="row">
            {data?.containers?.data
              .filter((container) => {
                return (
                  container.attributes.ContentCategory === "Title" ||
                  container.attributes.ContentCategory === "Keynote_Video" ||
                  container.attributes.ContentCategory === "Opening_Ceremony" ||
                  container.attributes.ContentCategory === "Daily_Summary_Video"
                );
              })
              .map((item) => (
                <div className="col-lg-12" key="item.id">
                  <div className="row">
                    {/* Card */}
                    <div className="p-3">
                      <div className="row">
                        <div
                          className={
                            item.attributes.Video !== null &&
                              item.attributes.Video.File.data !== null
                              ? "col-lg-6"
                              : "col-lg-12"
                          }
                        >
                          <h4 className="mt-3">{item.attributes.Title}</h4>
                          <AvatarGroup>
                            {item?.attributes?.ContentCategory === 'Keynote_Video' && item?.attributes?.Author !== '-' && item?.attributes?.Image?.data.length === 0 ? (
                              item?.attributes?.Author.split(",").map((txt) => (
                                <Avatar label={txt.charAt(0)} className="mt-3 mb-2" size="xlarge" shape="circle" />
                              ))
                            ) : (
                              item?.attributes?.Image?.data.map((img) => (
                                <Avatar image={img?.attributes?.url} className="mt-3 mb-2" size="xlarge" shape="circle" />
                              ))
                            )}
                          </AvatarGroup>
                          {/* <div className="fw-regular" dangerouslySetInnerHTML={{__html: item.attributes.Description}}>
                            
                          </div> */}
                          <ReactMarkdown className="fw-regular">
                            {item.attributes.Description}
                          </ReactMarkdown>

                        </div>
                        {item.attributes.Video !== null &&
                          item.attributes.Video.File.data !== null ? (
                          <div className="col-lg-6">
                            <Player
                              playsInline
                              fluid={false}
                              width={"100%"}
                              src={
                                item.attributes.Video &&
                                item.attributes.Video.File.data.attributes.url
                              }
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    {/* End Card */}
                  </div>
                </div>
              ))}
            {(data?.containers?.data.filter(
              (c) => c.attributes.ContentCategory === "Paper"
            )).length == 0 ? (
              <></>
            ) : (
              <div className="mb-4 p-3">
                <h4>Presentations:</h4>
                <Accordion>
                  {data?.containers?.data
                    .filter(
                      (container) =>
                        container.attributes.ContentCategory === "Paper"
                    )
                    .map((item, index) => (
                      <AccordionTab
                        header={
                          <React.Fragment>
                            <p className="mb-3 fw-bold">
                              Presentation {index + 1}
                            </p>
                            <p className="mb-2 fw-normal capital">
                              {item.attributes.Title}
                            </p>
                            <p className="mb-0 fw-light">
                              <small>
                                <i class="bi bi-people-fill"></i>&nbsp;{" "}
                                {item.attributes.Author}
                              </small>
                            </p>
                          </React.Fragment>
                        }
                      >
                        <div className="row">
                          <div
                            className={
                              item.attributes.Video !== null &&
                                item.attributes.Video.File.data !== null
                                ? "col-lg-6"
                                : "col-lg-12"
                            }


                          >
                            <div dangerouslySetInnerHTML={{ __html: item.attributes.Description }}></div>
                            <div className="mt-2">
                              <i className="bi bi-tags me-2"></i> {item.attributes.Tag}
                            </div>
                          </div>
                          <div
                            className={
                              item.attributes.Video !== null &&
                                item.attributes.Video.File.data !== null
                                ? "col-lg-6"
                                : "col-lg-12"
                            }
                          >
                            {item.attributes.Video == null ? (
                              <></>
                            ) : (
                              <Player
                                className="px-5"
                                playsInline
                                key="item.id"
                                src={
                                  item.attributes.Video?.File?.data?.attributes
                                    ?.url
                                }
                              />
                            )}
                          </div>
                          <div className="col-md-12">
                            <hr />
                            <div className="row">
                              {item.attributes.Document == null ||
                                item.attributes.Document?.File?.data.length ===
                                0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-md-4">
                                    <h4 className="mb-3 fs-6 d-flex align-items-center">
                                      {" "}
                                      <i className="bi bi-file-earmark"></i>{" "}
                                      &nbsp; Extended Abstract
                                      <Button
                                        onClick={() =>
                                          onClick2(
                                            item.attributes.Document?.File?.data
                                              ?.attributes
                                          )
                                        }
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-text"
                                        aria-label="View"
                                      />
                                    </h4>
                                  </div>
                                </>
                              )}

                              {
                                item.attributes.Presentation == null ||
                                  item.attributes.Presentation?.File?.data?.length === 0 ? (
                                  <></>
                                ) : (
                                  <>
                                    <div className="col-md-4">
                                      <h4 className="mb-3 fs-6 d-flex align-items-center">
                                        {" "}
                                        <i className="bi bi-easel"></i> &nbsp;{" "}
                                        Presentation
                                        <Button
                                          onClick={() =>
                                            onClick2(
                                              item.attributes.Presentation?.File
                                                ?.data?.attributes
                                            )
                                          }
                                          icon="pi pi-eye"
                                          className="p-button-rounded p-button-text"
                                          aria-label="View"
                                        />
                                      </h4>
                                    </div>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                      </AccordionTab>
                    ))}
                </Accordion>
              </div>
            )}

            {(data?.containers?.data.filter(
              (c) => c.attributes.ContentCategory === "Posters"
            )).length == 0 ? (
              <></>
            ) : (
              <div className="mb-4 p-3">
                <h4>Posters:</h4>
                <Accordion>
                  {data?.containers?.data
                    .filter(
                      (container) =>
                        container.attributes.ContentCategory === "Posters"
                    )
                    .map((item, index) => (
                      <AccordionTab
                        header={
                          <React.Fragment>
                            <p className="mb-3 fw-bold">Poster {index + 1}</p>
                            <p className="mb-2 fw-normal capital">
                              {item.attributes.Title}
                            </p>
                            <p className="mb-0 fw-light">
                              <small>
                                <i class="bi bi-people-fill"></i>&nbsp;{" "}
                                {item.attributes.Author}
                              </small>
                            </p>
                          </React.Fragment>
                        }
                      >
                        <div className="row">
                          <div
                            className={
                              item.attributes.Video !== null &&
                                item.attributes.Video.File.data !== null
                                ? "col-lg-6"
                                : "col-lg-12"
                            }

                          >
                            <div dangerouslySetInnerHTML={{ __html: item.attributes.Description }}></div>
                            <div className="mt-2">
                              <i className="bi bi-tags me-2"></i> {item.attributes.Tag}
                            </div>
                          </div>
                          <div
                            className={
                              item.attributes.Video !== null &&
                                item.attributes.Video.File.data !== null
                                ? "col-lg-6"
                                : "col-lg-12"
                            }
                          >
                            {item.attributes.Video == null ? (
                              <></>
                            ) : (
                              <Player
                                className="px-5"
                                playsInline
                                key="item.id"
                                src={
                                  item.attributes.Video?.File?.data?.attributes
                                    ?.url
                                }
                              />
                            )}
                          </div>
                          <div className="col-md-12">
                            <hr />
                            <div className="row">
                              {item.attributes.Document == null ||
                                item.attributes.Document?.File?.data.length ===
                                0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-md-4">
                                    <h4 className="mb-3 fs-6 d-flex align-items-center">
                                      {" "}
                                      <i className="bi bi-file-earmark"></i>{" "}
                                      &nbsp; Extended Abstract
                                      <Button
                                        onClick={() =>
                                          onClick2(
                                            item.attributes.Document?.File?.data
                                              ?.attributes
                                          )
                                        }
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-text"
                                        aria-label="View"
                                      />
                                    </h4>
                                  </div>
                                </>
                              )}
                              {console.log(item.attributes.Presentation?.File?.data)}
                              {item.attributes.Presentation == null ||
                                item.attributes.Presentation?.File?.data
                                  .length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-md-4">
                                    <h4 className="mb-3 fs-6 d-flex align-items-center">
                                      {" "}
                                      <i className="bi bi-easel"></i> &nbsp;{" "}
                                      Presentation
                                      <Button
                                        onClick={() =>
                                          onClick2(
                                            item.attributes.Presentation?.File
                                              ?.data?.attributes
                                          )
                                        }
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-text"
                                        aria-label="View"
                                      />
                                    </h4>
                                  </div>
                                </>
                              )}

                              {item.attributes.Poster == null ||
                                item.attributes.Poster?.File?.data.length ===
                                0 ? (
                                <></>
                              ) : (
                                <>
                                  <div className="col-md-4">
                                    <h4 className="mb-3 fs-6 d-flex align-items-center">
                                      {" "}
                                      <i className="bi bi-easel"></i> &nbsp;{" "}
                                      Poster
                                      <Button
                                        onClick={() =>
                                          onClick2(
                                            item.attributes.Poster?.File?.data
                                              ?.attributes
                                          )
                                        }
                                        icon="pi pi-eye"
                                        className="p-button-rounded p-button-text"
                                        aria-label="View"
                                      />
                                    </h4>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </AccordionTab>
                    ))}
                </Accordion>
              </div>
            )}

            {/* End Left side columns */}
          </div>
        </section>
      </main>

      <Dialog
        className="docview"
        header=""
        visible={displayBasicTwo}
        style={{ width: "90vw", height: "100vh" }}
        onHide={() => onHide("displayBasic")}
      >
        {ext === ".pdf" ? (
          <Iframe
            url={docs[0].uri + "#toolbar=0"}
            width="100%"
            height="100%"
            id=""
            className=""
            loading="Loading...."
            onload="console.log('test')"
            display="block"
            position="relative"
            onContextMenu={(e) => e.preventDefault()}
          />
        ) : (
          <></>
        )}

        {ext === ".doc" || ext === ".docx" ? (
          <Iframe
            url={
              "https://view.officeapps.live.com/op/embed.aspx?src=" +
              docs[0].uri
            }
            width="100%"
            height="100%"
            id=""
            className=""
            loading="Loading...."
            onload="console.log('test')"
            display="block"
            position="relative"
            oncontextmenu="return false;"
          />
        ) : (
          <></>
        )}

        {ext === ".ppt" || ext === ".pptx" ? (
          <Iframe
            url={
              "https://view.officeapps.live.com/op/embed.aspx?src=" +
              docs[0].uri
            }
            width="100%"
            height="100%"
            id=""
            className=""
            loading="Loading...."
            onload="console.log('test')"
            display="block"
            position="relative"
            oncontextmenu="return false;"
          />
        ) : (
          <></>
        )}

        {/*

        {displayBasicTwo && ext !== '.pdf' ? (
          <DocViewer
            documents={docs}
            pluginRenderers={DocViewerRenderers}
            style={{ height: 500 }}
            config={{
              header: {
                disableHeader: true,
                disableFileName: true,
                retainURLParams: false,
              },
              loadingRenderer: {
                overrideComponent: MyLoadingRenderer,
              },
              noRenderer: {
                overrideComponent: MyNoRenderer,
              },
            }}
          />
        ) : (
          <></>
        )}
         <Iframe url={"https://docs.google.com/gview?url="+docs+"&embedded=true"}
            width="100%"
            height="100%"
            id=""
            className=""
            loading="Loading...."
            display="block"
            position="relative"/>     */}
      </Dialog>
    </>
  );
}
